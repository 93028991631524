/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/23/2019
 * @Example 
 */

import { types } from "./W75F2000_actions";

const initialState = {
    getForm: [],
    getLeaveTypes: [],
    getRegisterTypes: [],
    dataCboYears: [],
    isExceptTypeLeave: 0,
    loadSetting: {},
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W75F2000_GET_LEAVE_TYPE_SUCCESS:
            return { ...state, getLeaveTypes: action.data };
        case types.W75F2000_GET_CBO_REGISTER_TYPE_SUCCESS:
            return { ...state, getRegisterTypes: action.data };
        case types.W75F2000_GET_FORM_SUCCESS:
            return { ...state, getForm: action.data };
        case types.W75F2000_GET_CBO_YEAR_SUCCESS:
            return { ...state, dataCboYears: action.data };
        case types.W75F2000_GET_IS_EXCEPT_TYPE_LEAVE_SUCCESS:
            return { ...state, isExceptTypeLeave: action.data?.IsExceptTypeLeave };
        case types.W75F2000_LOAD_SETTING_SUCCESS:
            return { ...state, loadSetting: action.data };
        default:
            return state;
    }
}
