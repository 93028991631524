import Api from '../../../services/api';
import {cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "../../W7X/W77F1010/W77F1010_actions";
import {delay} from "redux-saga";

export default function W771010Sagas() {
    return [
        watchGetDataGrid(),
        watchDeleteDataGrid()
    ];
}

//get data grid
export function* getDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w77f1010/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get data grid error', e);
    }
}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W77f1010_GET_DATA_GRID, getDataGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// delete data grid
export function* deleteDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w77f1010/delete', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get delete data grid', e);
    }
}

export function* watchDeleteDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W77f1010_DELETE, deleteDataGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
