/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/20/2019
 * @Example
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W75F2000_actions";
import { delay } from "redux-saga";
import _ from "lodash";
import Config from "../../../config";

export default function W75F2000Sagas() {
    return [
        watchGetGrid(),
        watchGetGridHistory(),
        watchGetGridDetailAdd(),
        watchGetGridDetailEdit(),
        watchDeleteLeave(),
        watchGetLeaveTypes(),
        watchGetLeave(),
        watchGetForm(),
        watchCheckRegisterType(),
        watchSaveLeave(),
        watchEditLeave(),
        watchGetCboRegisterType(),
        watchGetMinUnitRecordLeave(),
        watchGetMinUnitQuantity(),
        watchGetCboYears(),
        watchGetIsExceptTypeLeave(),
        watchCheckChooseTypeLeave(),
        watchLoadSetting(),
        watchExportLeaveOfAbsenceForm(),
    ];
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w75f2000/get-from", data.params);
        if (response && response.data) {
            yield put({ type: types.W75F2000_GET_FORM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_GET_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getLeaveTypes(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w75f2000/get-cbo-leave-type", data.params);
        if (response && response.data) {
            yield put({ type: types.W75F2000_GET_LEAVE_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get leave types error");
    }
}

export function* watchGetLeaveTypes() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_GET_LEAVE_TYPE, getLeaveTypes);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getLeave(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w75f2000/get-leave", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get leave error");
    }
}

export function* watchGetLeave() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_GET_LEAVE, getLeave);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//check register type...
export function* checkRegisterType(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w75f2000/load-store-w75f2111", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("check register type error");
    }
}

export function* watchCheckRegisterType() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_CHECK_REGISTER_TYPE, checkRegisterType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w75f2000/load-from", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get grid error");
    }
}

export function* watchGetGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_GET_GRID, getGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getGridHistory(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w75f2000/load-history", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get grid error");
    }
}

export function* watchGetGridHistory() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_GET_GRID_HISTORY, getGridHistory);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getGridDetailAdd(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w75f2000/load-detail-add", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get grid error");
    }
}

export function* watchGetGridDetailAdd() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_GET_GRID_DETAIL_ADD, getGridDetailAdd);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getGridDetailEdit(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w75f2000/load-detail-edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get grid error");
    }
}

export function* watchGetGridDetailEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_GET_GRID_DETAIL_EDIT, getGridDetailEdit);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteLeave(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w75f2000/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete leave error");
    }
}

export function* watchDeleteLeave() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_DELETE_LEAVE, deleteLeave);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//Save Leave...
export function* saveLeave(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w75f2000/save", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("save leave error");
    }
}

export function* watchSaveLeave() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_SAVE_LEAVE, saveLeave);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//Edit Leave...
export function* editLeave(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w75f2000/edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit leave error");
    }
}

export function* watchEditLeave() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_EDIT_LEAVE, editLeave);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboRegisterType(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w75f2000/load-register-type");
        if (response && response.data) {
            yield put({ type: types.W75F2000_GET_CBO_REGISTER_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit leave error");
    }
}

export function* watchGetCboRegisterType() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_GET_CBO_REGISTER_TYPE, getCboRegisterType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getMinUnitRecordLeave(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w75f2000/get-min-unit-record-leave");
        if (response && response.data) {
            yield put({ type: types.W75F2000_GET_MIN_UNIT_RECORD_LEAVE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit leave error");
    }
}

export function* watchGetMinUnitRecordLeave() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_GET_MIN_UNIT_RECORD_LEAVE, getMinUnitRecordLeave);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getMinUnitQuantity(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w75f2000/get-min-unit-quantity");
        if (response && response.data) {
            yield put({ type: types.W75F2000_GET_MIN_UNIT_QUANTITY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit leave error");
    }
}

export function* watchGetMinUnitQuantity() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_GET_MIN_UNIT_QUANTITY, getMinUnitQuantity);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getIsExceptTypeLeave(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w75f2000/get-is-except-type-leave");
        if (response && response.data) {
            yield put({ type: types.W75F2000_GET_IS_EXCEPT_TYPE_LEAVE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit leave error");
    }
}

export function* watchGetIsExceptTypeLeave() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_GET_MIN_UNIT_QUANTITY, getIsExceptTypeLeave);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* checkChooseTypeLeave(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w75f2000/choose-type-leave", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit leave error");
    }
}

export function* watchCheckChooseTypeLeave() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_CHECK_CHOOSE_TYPE_LEAVE, checkChooseTypeLeave);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboYears(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w75f2000/get-year");
        if (response && response.data) {
            let dataCboYearCustom = response.data;
            if (!_.isEmpty(response.data)) {
                dataCboYearCustom = response.data.map(item => {
                    const CycleFrom = _.get(item, "CycleFrom", "");
                    const CycleTo = _.get(item, "CycleTo", "");
                    const TranYear = _.get(item, "TranYear", "");
                    const line = _.isEmpty(CycleFrom) || _.isEmpty(CycleTo) ? "" : "-";
                    return { ...item, CycleYearCustom: `${TranYear}(${CycleFrom} ${line} ${CycleTo})` };
                });
            }
            yield put({ type: types.W75F2000_GET_CBO_YEAR_SUCCESS, data: dataCboYearCustom });
            data.cb && data.cb(null, dataCboYearCustom);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo error");
    }
}

export function* watchGetCboYears() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_GET_CBO_YEAR, getCboYears);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

function checkUrlFileName(filename) {
    //if file name had special character return valid data
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return filename = decodeURIComponent(reFileName);
    }
    return filename; // else return original data
}

export function* exportLeaveOfAbsenceForm(data) {
    try {
        yield delay(300);
        const response = yield Api.putExport("/w75f2000/export-leave-of-absence-form?TransID=" + data.params.TransID, null, "GET");
        if (response && response.data) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-hr-" + datetime + '.xls';
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("export-leave-of-absence-form error");
    }
}

export function* watchExportLeaveOfAbsenceForm() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_EXPORT_LEAVE_OF_ABSENCE_FORM, exportLeaveOfAbsenceForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* loadSetting(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w75f2000/load-setting');
        if (response && response.data) {
            yield put({ type: types.W75F2000_LOAD_SETTING_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load setting error');
    }

}

export function* watchLoadSetting() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2000_LOAD_SETTING, loadSetting);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
