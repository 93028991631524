/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/20/2022
 * @Example 
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types }                    from "./W82F1000_actions";
import { delay }                    from "redux-saga";
export default function W82F1000Sagas() {
    return [
        watchLoadColumn(),
        watchLoadGrid(),
        watchIssueEquipment(),
    ];
}

export function* loadColumn(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w82f1000/load-grid-column', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load column error');
    }

}

export function* watchLoadColumn() {
    while (true) {
        const watcher = yield takeLatest(types.W82F1000_LOAD_COLUMN, loadColumn);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w82f1000/get-data-load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load grid error');
    }

}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W82F1000_LOAD_GRID, loadGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* issueEquipment(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w82f1000/issue-equipment', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('issue equipment error');
    }

}

export function* watchIssueEquipment() {
    while (true) {
        const watcher = yield takeLatest(types.W82F1000_ISSUE_EQUIPMENT, issueEquipment);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
