export const types = {
    W75F8003_LOAD_FORM: "W75F8003_LOAD_FORM",
    W75F8003_LOAD_FORM_SUCCESS: "W75F8003_LOAD_FORM_SUCCESS",
    W75F8003_SAVE_ROW: "W75F8003_SAVE_ROW",
    W75F8003_DELETE_ROW: "W75F8003_DELETE_ROW",

};

export function loadForm(params, cb) {
    return {
        type: types.W75F8003_LOAD_FORM,
        params,
        cb
    }
}

export function saveRow(params, cb) {
    return {
        type: types.W75F8003_SAVE_ROW,
        params,
        cb
    }
}

export function deleteRow(params, cb) {
    return {
        type: types.W75F8003_DELETE_ROW,
        params,
        cb
    }
}