/**
 * @copyright 20202 @ DigiNet
 * @author THIEN PHAM
 * @create 8/20/2019
 * @Example
 */
export const types = {
    W77F1000_LOAD_GRID: "W77F1000_LOAD_GRID",
    W77F1000_LOAD_GRID_SUCCESS: "W77F1000_LOAD_GRID_SUCCESS",
};

export function loadGrid(params, cb) {
    return {
        type: types.W77F1000_LOAD_GRID,
        params,
        cb
    }
}
