/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI 
 * @create 30/12/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W75F2008_actions";

export default function W75F2008Sagas() {
    return [
        watchLoadGrid(),
        watchApproval(),
        watchConfirmApproval(),
    ];
}

export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w75f2008/load-grid', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2008_LOAD_GRID, loadGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* approval(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w75f2008/approval', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('approval error');
    }

}

export function* watchApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2008_APPROVAL, approval);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* confirmApproval(data) {
    try {
        yield delay(300);
        const response = yield Api.post('/w75f2008/confirm-approval', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('approval error');
    }

}

export function* watchConfirmApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2008_CONFIRM_APPROVAL, confirmApproval);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}