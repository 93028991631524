/**
 * @copyright 2020 @ DigiNet
 * @author THANHHAI
 * @create 23/12/2020
 * @Example
 */
export const types = {
    W84F3010_LOAD_DATA: "W84F3010_LOAD_DATA",

  };

  export function getData(params, cb) {
  return {
    type: types.W84F3010_LOAD_DATA,
    params,
    cb
  };
  }

