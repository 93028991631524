/**
 * @copyright 2020 @ DigiNet
 * @author THANHHAI
 * @create 23/12/2020
 * @Example
 */
export const types = {
    W84F3005_LOAD_FORM_GRID: "W84F3005_LOAD_FORM_GRID",
    W84F3005_LOAD_FORM_GRID_SUCCESS: "W84F3005_LOAD_FORM_GRID_SUCCESS",
   
  };
  
  export function getFormGrid(params, cb) {
    return {
      type: types.W84F3005_LOAD_FORM_GRID,
      params,
      cb
    };
  };
  
