/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 09/01/2020
 * @Example
 */

import { types } from "./W75F2010_actions";
const initialState = {
  getDataGrid: [],
  getCboPeriod: [],
  getCboAppStatus: [],
  getCboAbsentType: [],
  getForm: []
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case types.W75F2010_LOAD_GRID_SUCCESS: {
      return {
        ...state,
        getDataGrid: action.data
      };
    }
    case types.W75F2010_LOAD_CBO_PERIOD_SUCCESS: {
      return {
        ...state,
        getCboPeriod: action.data
      };
    }
    case types.W75F2010_LOAD_APPROVAL_STATUS_SUCCESS: {
      return {
        ...state,
        getCboAppStatus: action.data
      };
    }
    case types.W75F2010_LOAD_CBO_ABSENT_TYPE_SUCCESS: {
      return {
        ...state,
        getCboAbsentType: action.data
      };
    }
    case types.W75F2010_GET_FORM_SUCCESS: {
      return { ...state, getForm: action.data };
    }
    default:
      return state;
  }
}