/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 10/05/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W51F1101_actions";

export default function W51F1101Sagas() {
    return [
        watchLoadForm(),
        watchGetCaption(),
        watchSaveAdd(),
        watchSaveEdit(),
        watchGetWareHouse()
    ];
}

// load Form
export function* loadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w51f1101/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load form error', e);
    }
}

export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_LOAD_FORM, loadForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCaption(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w51f1001/load-caption", data.params);
        if (res && res.data) {
            yield put({ type: types.W51F1001_GET_CAPTION_SUCCESS, data: res.data, });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('get caption error', e);
    }
}

export function* watchGetCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_GET_CAPTION, getCaption);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* saveAdd(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w51f1001/add", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('save error', e);
    }
}

export function* watchSaveAdd() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_SAVE_ADD, saveAdd);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* saveEdit(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w51f1001/edit", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('save error', e);
    }
}

export function* watchSaveEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_SAVE_EDIT, saveEdit);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getWareHouse(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w51f1101/get-ware-house");
        if (res && res.data) {
            yield put({ type: types.W51F1001_GET_WARE_HOUSE_SUCCESS, data: res.data, });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('save error', e);
    }
}

export function* watchGetWareHouse() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_GET_WARE_HOUSE, getWareHouse);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
