/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 10/05/2021
 * @Example
 */

import { types } from "./W51F1101_actions";
const initialState = {
  flexibleColumn: [],
  wareHouseData: {}
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case types.W51F1001_GET_CAPTION_SUCCESS: {
      return { ...state, flexibleColumn: action.data };
    }
    case types.W51F1001_GET_WARE_HOUSE_SUCCESS: {
      return { ...state, wareHouseData: action.data, };
    }
    default:
      return state;
  }
}