/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 5/5/2020
 * @Example
 */

export const types = {
    W51F1001_ADD: "W51F1001_ADD",
    W51F1001_EDIT: "W51F1001_EDIT",
    W51F1001_COPY: "W51F1001_COPY",
    W51F1001_LOAD_CHOOSE: "W51F1001_LOAD_CHOOSE",
    W51F1001_COUNT: "W51F1001_COUNT",
    W51F1001_GET_CAPTION: "W51F1001_GET_CAPTION",
    W51F1001_GET_CAPTION_SUCCESS: "W51F1001_GET_CAPTION_SUCCESS",
    W51F1001_GET_MASTER: "W51F1001_GET_MASTER",
    W51F1001_GET_MASTER_SUCCESS: "W51F1001_GET_MASTER_SUCCESS",
    W51F1001_GET_DETAIL: "W51F1001_GET_DETAIL",
    W51F1001_GET_DETAIL_SUCCESS: "W51F1001_GET_DETAIL_SUCCESS",
    W51F1001_GET_CB_REASON: "W51F1001_GET_CB_REASON",
    W51F1001_GET_CB_SIZE: "W51F1001_GET_CB_SIZE",
    W51F1001_GET_WARE_HOUSE: "W51F1001_GET_WARE_HOUSE",
    W51F1001_GET_WARE_HOUSE_SUCCESS: "W51F1001_GET_WARE_HOUSE_SUCCESS",
    W51F1001_GET_PROPOSAL: "W51F1001_GET_PROPOSAL",
    W51F1001_GET_PROPOSAL_SUCCESS: "W51F1001_GET_PROPOSAL_SUCCESS",
};

export function add(params, cb) {
    return {
        type: types.W51F1001_ADD,
        params,
        cb,
    };
}
export function edit(params, cb) {
    return {
        type: types.W51F1001_EDIT,
        params,
        cb,
    };
}
export function copy(params, cb) {
    return {
        type: types.W51F1001_COPY,
        params,
        cb,
    };
}
export function loadChoose(params, cb) {
    return {
        type: types.W51F1001_LOAD_CHOOSE,
        params,
        cb,
    };
}
export function count(params, cb) {
    return {
        type: types.W51F1001_COUNT,
        params,
        cb,
    };
}
export function getCbSize(cb) {
    return {
        type: types.W51F1001_GET_CB_SIZE,
        cb,
    };
}

export function getCbReason(cb) {
    return {
        type: types.W51F1001_GET_CB_REASON,
        cb,
    };
}

export function getCaption(params, cb) {
    return {
        type: types.W51F1001_GET_CAPTION,
        cb,
        params,
    };
}

export function getMaster(params, cb) {
    return {
        type: types.W51F1001_GET_MASTER,
        cb,
        params,
    };
}

export function getDetail(params, cb) {
    return {
        type: types.W51F1001_GET_DETAIL,
        cb,
        params,
    };
}

export function getWareHouse(cb) {
    return {
        type: types.W51F1001_GET_WARE_HOUSE,
        cb
    }
}

export function getProposal(params, cb) {
    return {
        type: types.W51F1001_GET_PROPOSAL,
        params,
        cb
    }
}