/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/20/2019
 * @Example
 */

import Api from '../../../services/api';
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W75F8003_actions";
import { delay } from "redux-saga";
import _ from "lodash";

export default function W75F8003Sagas() {
    return [watchLoadForm(), watchSaveRow(), watchDeleteRow()];
}

export function* loadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w75f8003/load-form', data.params);
        if (response && response.data) {
            yield put({ type: types.W75F8003_LOAD_FORM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get form error');
    }

}

export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.W75F8003_LOAD_FORM, loadForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* saveRow(data) {
    try {
        yield delay(300);
        const response = yield Api.post('/w75f8003/add', data.params);
        if (response && _.toNumber(response?.data?.Status === 0)) {
            data.cb && data.cb(null, response.data)
        } else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get form error', e);
    }

}

export function* watchSaveRow() {
    while (true) {
        const watcher = yield takeLatest(types.W75F8003_SAVE_ROW, saveRow);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.delete('/w75f8003/del', data.params);
        if (response && _.toNumber(response?.data?.Status === 0)) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get form error');
    }

}

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.W75F8003_DELETE_ROW, deleteRow);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

