/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI 
 * @create 30/12/2021
 * @Example
 */
export const types = {
    W75F2008_LOAD_GRID: "W75F2008_LOAD_GRID",
    W75F2008_APPROVAL: "W75F2008_APPROVAL",
    W75F2008_CONFIRM_APPROVAL: "W75F2008_CONFIRM_APPROVAL",
};

export function loadGrid(params, cb) {
    return {
        type: types.W75F2008_LOAD_GRID,
        params,
        cb
    }
}

export function approval(params, cb) {
    return {
        type: types.W75F2008_APPROVAL,
        params,
        cb
    }
}

export function confirmApproval(params, cb) {
    return {
        type: types.W75F2008_CONFIRM_APPROVAL,
        params,
        cb
    }
}
