/**
 * @copyright 2023 @ DigiNet
 * @author THANHAN
 * @create 4/1/2023
 * @Example
 */

import { types } from './W51F2201_actions';

const initialState = {
    formData: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W51F2201_LOAD_FORM_SUCCESS: {
            return { ...state, formData: action.data };
        }
        default:
            return state;
    }
}