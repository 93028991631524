/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 26/12/2019
 * @Example
 */

import Api                             from '../../../services/api';
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types}                         from "./W75F2005_actions";
import {delay}                         from "redux-saga";
import Config                          from "../../../config";

export default function W75F2005Sagas() {
    return [
        watchLoadGrid(),
        watchGetLeaveTypes(),
        watchSaveData(),
        watchOnExport(),
    ];
}

function checkUrlFileName(filename) {
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return decodeURIComponent(reFileName);
    }
    return filename;
}

export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w75f2005/load-grid', data.params);
        if (response && response.data) {
            yield put({type: types.W75F2005_LOAD_GRID_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchLoadGrid() {
    while (true){
        const watcher = yield takeLatest(types.W75F2005_LOAD_GRID, loadGrid);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getLeaveTypes(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W75F2000/get-cbo-leave-type', data.params);
        if (response && response.data) {
            yield put({ type: types.W75F2005_GET_LEAVE_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get leave types error');
    }

}

export function* watchGetLeaveTypes() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2005_GET_LEAVE_TYPE, getLeaveTypes);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* saveData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w75f2005/save', data.params);
        if (response && response.data) {
            yield put({type: types.W75F2005_SAVE_DATA_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchSaveData() {
    while (true){
        const watcher = yield takeLatest(types.W75F2005_SAVE_DATA, saveData);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}


export function* onExport(data) {
    try {
        yield delay(300);
        const response = yield Api.putExport('/w75f2005/export-excel', data.params);
        if (response && response.ok) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-hr-" + datetime + '.xls';
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log('onExport W75F2005 error');
    }
}

export function* watchOnExport() {
    while (true) {
        const watcher = yield takeLatest(types.W75F2005_EXPORT_EXCEL, onExport);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}