/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 6/1/2020
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W51F1001_actions";
import { delay } from "redux-saga";

export default function W51F1001Sagas() {
    return [
        watchGetCaption(),
        watchGetMaster(),
        watchGetDetail(),
        watchGetCbSize(),
        watchGetCbReason(),
        watchGetWareHouse(),
        watchGetProposal(),
        watchAdd(),
        watchEdit(),
        watchCopy(),
        watchLoadChoose(),
        watchCount(),
    ];
}

export function* add(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w51f1001/add", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {}
}

export function* watchAdd() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_ADD, add);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* edit(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w51f1001/edit", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {}
}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_EDIT, edit);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* copy(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w51f1001/coppy", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {}
}

export function* watchCopy() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_COPY, copy);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadChoose(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w51f1001/load-choose", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {}
}

export function* watchLoadChoose() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_LOAD_CHOOSE, loadChoose);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* count(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w51f1001/count", data.params);
        if (res && res.data) {
            yield put({
                type: types.W51F1001_GET_CAPTION_SUCCESS,
                data: res.data,
            });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {}
}

export function* watchCount() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_COUNT, count);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCaption(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w51f1001/load-caption", data.params);
        if (res && res.data) {
            yield put({
                type: types.W51F1001_GET_CAPTION_SUCCESS,
                data: res.data,
            });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {}
}

export function* watchGetCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_GET_CAPTION, getCaption);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getMaster(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w51f1001/load-master", data.params);
        if (res && res.data) {
            yield put({
                type: types.W51F1001_GET_MASTER_SUCCESS,
                data: res.data,
            });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {}
}

export function* watchGetMaster() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_GET_MASTER, getMaster);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getDetail(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w51f1001/load-detail", data.params);
        if (res && res.data) {
            yield put({
                type: types.W51F1001_GET_DETAIL_SUCCESS,
                data: res.data,
            });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {}
}

export function* watchGetDetail() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_GET_DETAIL, getDetail);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCbReason(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w51f1001/load-cbo-reason", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {}
}

export function* watchGetCbReason() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_GET_CB_REASON, getCbReason);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCbSize(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w51f1001/load-cbo-size", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {}
}

export function* watchGetCbSize() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_GET_CB_SIZE, getCbSize);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getWareHouse(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w51f1101/get-ware-house");
        if (res && res.data) {
            yield put({ type: types.W51F1001_GET_WARE_HOUSE_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("save error", e);
    }
}

export function* watchGetWareHouse() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_GET_WARE_HOUSE, getWareHouse);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getProposal(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w51f1001/load-proposal");
        if (res && res.data) {
            yield put({ type: types.W51F1001_GET_PROPOSAL_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("save error", e);
    }
}

export function* watchGetProposal() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1001_GET_PROPOSAL, getProposal);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
