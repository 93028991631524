/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 26/12/2019
 * @Example
 */
export const types = {
    W75F2005_LOAD_GRID: "W75F2005_LOAD_GRID",
    W75F2005_SAVE_DATA: "W75F2005_SAVE_DATA",
    W75F2005_LOAD_GRID_SUCCESS: "W75F2005_LOAD_GRID_SUCCESS",
    W75F2005_SAVE_DATA_SUCCESS: "W75F2005_SAVE_DATA_SUCCESS",
    W75F2005_GET_LEAVE_TYPE: "W75F2005_GET_LEAVE_TYPE",
    W75F2005_GET_LEAVE_TYPE_SUCCESS: "W75F2005_GET_LEAVE_TYPE_SUCCESS",
    W75F2005_EXPORT_EXCEL: "W75F2005_EXPORT_EXCEL",
};

export function loadGrid(params, cb) {
    return {
        type: types.W75F2005_LOAD_GRID,
        params,
        cb
    }
}

export function getLeaveTypes(params, cb) {
    return {
        type: types.W75F2005_GET_LEAVE_TYPE,
        params,
        cb
    }
}

export function saveData(params, cb) {
    return {
        type: types.W75F2005_SAVE_DATA,
        params,
        cb
    }
}

export function exportExcel(params, cb) {
    return {
        type: types.W75F2005_EXPORT_EXCEL,
        params,
        cb
    }
}
