/**
 * @copyright 2023 @ DigiNet
 * @author TRANGHOANG
 * @create 10/1/2023
 * @Example
 */

import Api from "../../../services/api";
import {cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "./W51F2200_actions";
import {delay} from "redux-saga";

export default function W09F2010Sagas() {
    return [
        watchGetDataGrid(),
        watchDeleteRow(),
    ];
}

//get data grid
export function* getDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w51f2200/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W51F2200_LOAD_GRID, getDataGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//deleteData
export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w51f2200/del", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("save absent error");
    }
}

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.W51F2200_DELETE_ROW, deleteRow);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

