import { types } from "./W77F1011_actions";

const initialState = {
    getCboCostTypes: [],
    getFieldHide: [],

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W77F1011_LOAD_CBO_COST_TYPE_SUCCESS: {
            return { ...state, getCboCostTypes: action.data };
        }
        case types.W77F1011_GET_FIELD_HIDE_SUCCESS: {
            return { ...state, getFieldHide: action.data };
        }
        default: {
            return state;
        }
    }
}
