/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example
 */

import Api from '../../../services/api';
import {cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "../../W51/W51F1002/W51F1002_actions";
import {delay} from "redux-saga";

export default function W09F6000Sagas() {
    return [
        watchGetGridEquipments(),
    ];
}


//get grid Equipments..
export function* getGridEquipments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w51f1002/load-grid', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid employees error', e);
    }

}

export function* watchGetGridEquipments() {
    while (true){
        const watcher = yield takeLatest(types.W51F1002_GET_GRID_EQUIPMENTS, getGridEquipments);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}



