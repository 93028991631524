/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 26/12/2019
 * @Example
 */

import {types} from "./W75F2005_actions"

const initialState = {
    loadGrid: [],
    getLeaveTypes: [],
    saveData: [],
};

export default function(state = initialState,action = {}) {
    if(action.type === types.W75F2005_LOAD_GRID_SUCCESS)
    {
        return {
            ...state,
            loadGrid: action.data
        }
    }
    if(action.type === types.W75F2005_GET_LEAVE_TYPE_SUCCESS) {
        return { 
            ...state, 
            getLeaveTypes: action.data
        };
    }
    if(action.type === types.W75F2005_SAVE_DATA_SUCCESS)
    {
        return {
            ...state,
            saveData: action.data
        }
    }
    return state
}