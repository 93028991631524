/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/20/2022
 * @Example 
 */
export const types = {
    W82F1000_LOAD_COLUMN: "W82F1000_LOAD_COLUMN",
    W82F1000_LOAD_GRID: "W82F1000_LOAD_GRID",
    W82F1000_ISSUE_EQUIPMENT: "W82F1000_ISSUE_EQUIPMENT",
};

export function loadColumn(params, cb) {
    return {
        type: types.W82F1000_LOAD_COLUMN,
        params,
        cb,
    };
}

export function loadGrid(params, cb) {
    return {
        type: types.W82F1000_LOAD_GRID,
        params,
        cb,
    };
}

export function issueEquipment(params, cb) {
    return {
        type: types.W82F1000_ISSUE_EQUIPMENT,
        params,
        cb,
    };
}

