/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 31/10/2019
 * @Example
 */

import Api from '../../../services/api';
import {put, cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "./W75F4090_actions";
import {delay} from "redux-saga";

export function* getCombo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w75f4090/get-combo', data.params);
        if (response && response.data) {
            yield put({type: types.W75F4090_GET_COMBO_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get personal error');
    }

}

export function* watchGetCombo() {
    while (true){
        const watcher = yield takeLatest(types.W75F4090_GET_COMBO, getCombo);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getList(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w75f4090/get-list', data.params);
        if (response && response.data) {
            yield put({type: types.W75F4090_GET_LIST_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get personal error');
    }
}

export function* watchGetList() {
    while (true){
        const watcher = yield takeLatest(types.W75F4090_GET_LIST, getList);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
