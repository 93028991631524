/**
 * @copyright 2023 @ DigiNet
 * @author TRANGHOANG
 * @create 10/1/2023
 * @Example
 */
export const types = {
  W51F2200_LOAD_GRID: "W51F2200_GET_DATA_GRID",
  W51F2200_DELETE_ROW: "W51F2200_DELETE_ROW",
};

export function loadGrid(params, cb) {
  return {
    type: types.W51F2200_LOAD_GRID,
    params,
    cb
  };
}

export function deleteRow(params, cb) {
  return {
    type: types.W51F2200_DELETE_ROW,
    params,
    cb
  };
}
