/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 10/05/2021
 * @Example
 */

export const types = {
    W51F1001_LOAD_FORM: "W51F1001_LOAD_FORM",
    W51F1001_GET_CAPTION: "W51F1001_GET_CAPTION",
    W51F1001_GET_CAPTION_SUCCESS: "W51F1001_GET_CAPTION_SUCCESS",
    W51F1001_SAVE_EDIT: "W51F1001_SAVE_EDIT",
    W51F1001_SAVE_ADD: "W51F1001_SAVE_ADD",
    W51F1001_GET_WARE_HOUSE: "W51F1001_GET_WARE_HOUSE",
    W51F1001_GET_WARE_HOUSE_SUCCESS: "W51F1001_GET_WARE_HOUSE_SUCCESS",
};

export function loadForm(params, cb) {
    return {
        type: types.W51F1001_LOAD_FORM,
        params,
        cb
    }
}

export function getCaption(params, cb) {
    return {
        type: types.W51F1001_GET_CAPTION,
        params,
        cb
    }
}

export function saveEdit(params, cb) {
    return {
        type: types.W51F1001_SAVE_EDIT,
        params,
        cb
    }
}

export function saveAdd(params, cb) {
    return {
        type: types.W51F1001_SAVE_ADD,
        params,
        cb
    }
}


export function getWareHouse(cb) {
    return {
        type: types.W51F1001_GET_WARE_HOUSE,
        cb
    }
}



