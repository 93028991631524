/**
 * @copyright 2023 @ DigiNet
 * @author THANHAN
 * @create 4/1/2023
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W51F2201_actions";
import { delay } from "redux-saga";

export default function W51F2201Sagas() {
    return [
        watchLoadNormName(),
        watchLoadForm(),
        watchCalculate(),
        watchOnAdd(),
        watchOnEdit(),
        watchGetIsCalEliNorms(),
    ];
}

export function* loadNormName(data) {
    try {
        yield delay(300);
        const res = yield Api.get('/w51f2201/load-norm-name');
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        }
        else {
            data.cb && data.cb(res, null);
        }
    }
    catch (e) {
        console.log('load norm name error', e);
    }
}

export function* watchLoadNormName() {
    while (true) {
        const watcher = yield takeLatest(types.W51F2201_LOAD_NORM_NAME, loadNormName);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getIsCalEliNorms(data) {
    try {
        yield delay(300);
        const res = yield Api.get('/w51f2201/get-isCalEliNorms');
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        }
        else {
            data.cb && data.cb(res, null);
        }
    }
    catch (e) {
        console.log('load is calelinorms name error', e);
    }
}

export function* watchGetIsCalEliNorms() {
    while (true) {
        const watcher = yield takeLatest(types.W51F2201_GET_CALELINORM, getIsCalEliNorms);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadForm(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w51f2201/load-form", data.params);
        if (res && res.data) {
            yield put({type: "W51F2201_LOAD_FORM_SUCCESS", data: res.data});
            data.cb && data.cb(null, res.data);
            return
        }
        else {
            data.cb && data.cb(res, null);
        }
    }
    catch (e) {
        console.log("load form error", e);
    }
}

export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.W51F2201_LOAD_FORM, loadForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* calculate(data) {
    try {
        yield delay(300);
        const res = yield Api.post('/w51f2201/calculator', data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data)
        }
        else {
            data.cb && data.cb(res, null)
        }
    }
    catch (e) {
        console.log("calculate error", e)
    }
}

export function* watchCalculate() {
    while (true) {
        const watcher = yield takeLatest(types.W51F2201_CALCULATE, calculate);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* onAdd(data) {
    try {
        yield delay(300);
        const res = yield Api.post('/w51f2201/add',data.params);
        if (res && res.data){
            data.cb && data.cb(null, res.data);
        }
        else {
            data.cb(res, null);
        }
    }
    catch (e) {
        console.log("save error",e)
    }
}

export function* watchOnAdd(){
    while (true) {
        const watcher = yield takeLatest(types.W51F2201_ON_ADD, onAdd);
        yield take(["LOGOUT","NETWORK"]);
        yield cancel(watcher);
    }
}

export function* onEdit(data) {
    try {
        yield delay(300);
        const res = yield Api.put('/w51f2201/edit',data.params);
        if (res && res.data){
            data.cb && data.cb(null, res.data);
        }
        else {
            data.cb(res, null);
        }
    }
    catch (e) {
        console.log("edit error",e)
    }
}

export function* watchOnEdit(){
    while (true) {
        const watcher = yield takeLatest(types.W51F2201_ON_EDIT, onEdit);
        yield take(["LOGOUT","NETWORK"]);
        yield cancel(watcher);
    }
}