/**
 * @copyright 2020 @ DigiNet
 * @author THANHHAI
 * @create 23/12/2020
 * @Example
 */
import { types } from "./W84F3005_actions";
const initialState = {
  getFormGrid: [],
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case types.W84F3005_LOAD_FORM_GRID_SUCCESS: {
      return {
        ...state,
        getFormGrid: action.data,
      };
    }
    default:
      return state;
  }
}