/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/23/2019
 * @Example 
 */

import { types } from "./W75F8003_actions";

const initialState = {
    getForm: {},
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W75F8003_LOAD_FORM_SUCCESS:
            return { ...state, getForm: action.data };
        default:
            return state;
    }
}
