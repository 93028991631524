/**
 * @copyright 2021 @ DigiNet
 * @author TRANGHOANG
 * @create 20/08/2021
 */
import Api from "../../../services/api";
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W84F3010_actions";
import { delay } from "redux-saga";

export default function W84F3005Sagas() {
    return [watchGetData()];
}

export function* getData(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/W84F3010/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form grid error");
    }
}

export function* watchGetData() {
    while (true) {
        const watcher = yield takeLatest(
            types.W84F3010_LOAD_DATA,
            getData
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
