/**
import { CheckBox } from 'devextreme-react';
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 09/01/2020
 * @Example
 */

export const types = {
  W75F2010_LOAD_GRID: "W75F2010_GET_DATA_GRID",
  W75F2010_LOAD_GRID_SUCCESS: "W75F2010_GET_DATA_GRID_SUCCESS",
  W75F2010_LOAD_CBO_PERIOD: "W75F2010_LOAD_CBO_PERIOD",
  W75F2010_LOAD_CBO_PERIOD_SUCCESS: "W75F2010_LOAD_CBO_PERIOD_SUCCESS",
  W75F2010_LOAD_APPROVAL_STATUS: "W75F2010_LOAD_APPROVAL_STATUS",
  W75F2010_LOAD_APPROVAL_STATUS_SUCCESS:"W75F2010_LOAD_APPROVAL_STATUS_SUCCESS",
  W75F2010_LOAD_CBO_ABSENT_TYPE: "W75F2010_LOAD_CBO_ABSENT_TYPE",
  W75F2010_LOAD_CBO_ABSENT_TYPE_SUCCESS:"W75F2010_LOAD_CBO_ABSENT_TYPE_SUCCESS",
  W75F2010_DELETE_DATA: "W75F2010_DELETE_DATA",
  W75F2010_DELETE_DATA_SUCCESS: "W75F2010_DELETE_DATA_SUCCESS",
  W75F2010_GET_FORM: "W75F2010_GET_FORM",
  W75F2010_GET_FORM_SUCCESS: "W75F2010_GET_FORM_SUCCESS",
  W75F2010_LOAD_ATTACHMENTS: "W75F2010_LOAD_ATTACHMENTS",
  W75F2010_SAVE_ABSENT: "W75F2010_SAVE_ABSENT",
  W75F2010_EDIT_ABSENT: "W75F2010_EDIT_ABSENT"
};

export function getForm(params, cb) {
  return {
    type: types.W75F2010_GET_FORM,
    params,
    cb
  };
}

export function loadGrid(params, cb) {
  return {
    type: types.W75F2010_LOAD_GRID,
    params,
    cb
  };
}

export function loadPeriod(params, cb) {
  return {
    type: types.W75F2010_LOAD_CBO_PERIOD,
    params,
    cb
  };
}

export function loadAppStatus(params, cb) {
  return {
    type: types.W75F2010_LOAD_APPROVAL_STATUS,
    params,
    cb
  };
}

export function saveAbsent(params, cb) {
  return {
    type: types.W75F2010_SAVE_ABSENT,
    params,
    cb
  };
}

export function editAbsent(params, cb) {
  return {
    type: types.W75F2010_EDIT_ABSENT,
    params,
    cb
  };
}

export function loadAbsentType(cb) {
  return {
    type: types.W75F2010_LOAD_CBO_ABSENT_TYPE,
    cb
  };
}

export function deleteDataGrid(params, cb) {
  return {
    type: types.W75F2010_DELETE_DATA,
    params,
    cb
  };
}
