/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 07/05/2021
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest, put } from "redux-saga/effects";
import { types } from "./W51F1100_actions";
import { delay } from "redux-saga";

export default function W09F6000Sagas() {
    return [
        watchLoadGrid(),
        watchDeleteRowData()
    ];
}

//load grid
export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w51f1100/load-form', data.params);
        if (response && response.data) {
            yield put({ type: types.W51F1100_LOAD_GRID_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load grid error', e);
    }
}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1100_LOAD_GRID, loadGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//delete row
export function* deleteRowData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w51f1002/load-grid', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('delete row data error', e);
    }
}

export function* watchDeleteRowData() {
    while (true) {
        const watcher = yield takeLatest(types.W51F1100_DELETE_ROW_DATA, deleteRowData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}



