/**
 * @copyright 2020 @ DigiNet
 * @author THANHHAI
 * @create 23/12/2020
 * @Example
 */
import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W84F3005_actions";
import { delay } from "redux-saga";

export default function W84F3005Sagas() {
    return [watchGetFormGrid()];
}

export function* getFormGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/W84F3005/load-form", data.params);
        if (response && response.data) {
            yield put({
                type: types.W84F3005_LOAD_FORM_GRID_SUCCESS,
                data: response.data,
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form grid error");
    }
}

export function* watchGetFormGrid() {
    while (true) {
        const watcher = yield takeLatest(
            types.W84F3005_LOAD_FORM_GRID,
            getFormGrid
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
