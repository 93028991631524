/**
 * @copyright 2023 @ DigiNet
 * @author THANHAN
 * @create 4/1/2023
 * @Example
 */

export const types = {
    W51F2201_LOAD_NORM_NAME: 'W51F2201_LOAD_NORM_NAME',
    W51F2201_LOAD_FORM: 'W51F2201_LOAD_FORM',
    W51F2201_LOAD_FORM_SUCCESS: 'W51F2201_LOAD_FORM_SUCCESS',
    W51F2201_CALCULATE: "W51F2201_CALCULATE",
    W51F2201_ON_ADD: "W51F2201_ON_ADD",
    W51F2201_ON_EDIT: "W51F2201_ON_EDIT",
    W51F2201_GET_CALELINORM: "W51F2201_GET_CALELINORM",
}

export function loadNormName(cb) {
    return {
        type: 'W51F2201_LOAD_NORM_NAME',
        cb
    }
}

export function loadForm(params, cb,) {
    return {
        type: 'W51F2201_LOAD_FORM',
        params,
        cb
    }
}

export function getIsCalEliNorms(cb) {
    return {
        type: 'W51F2201_GET_CALELINORM',
        cb,
    }
}

export function calculate(params, cb) {
    return {
        type: 'W51F2201_CALCULATE',
        params,
        cb
    }
}

export function add(params, cb) {
    return {
        type: 'W51F2201_ON_ADD',
        params,
        cb
    }
}

export function edit(params, cb) {
    return {
        type: 'W51F2201_ON_EDIT',
        params,
        cb
    }
}